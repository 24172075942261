import { useRef, useState, useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import {
  Box,
  Flex,
  Input,
  Image,
  FormControl,
  FormLabel,
  Text,
  Textarea,
  Button,
} from '@chakra-ui/react';
import { fadeIn } from '../varients';
import { motion } from 'framer-motion';

const Career = () => {
  const [clicked, setClicked] = useState(false);
  const inputFile = useRef(null);
  const [file, setFile] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [query, setQuery] = useState('');
  const [isValid, setIsValid] = useState(false);

  const handleChange = e => {
    setFile([...file, e.target.files[0]]);
  };

  const handleSubmit = () => {
    if (isValid) {
      setClicked(true);
      console.log('Form Submitted');
    }
  };

  useEffect(() => {
    const validateEmail = email => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    };

    setIsValid(
      name.trim() !== '' && validateEmail(email) && query.trim() !== ''
    );
  }, [name, email, query]);
  const MotionBox = motion(Box);
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.7 } // Adjust threshold as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Box>
      <Header />
      <Box background="linear-gradient( to left, rgba(131, 101, 142, 0.32) 10%, white 80%)">
        <Flex
          flexDirection={{
            xl: 'row',
            lg: 'row',
            md: 'row',
            base: 'column-reverse',
          }}
          justifyContent={'space-around'}
          alignItems={'center'}
          width={'100%'}
        >
          <MotionBox 
          ref={ref}
          variants={fadeIn('right', 0.7)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          animate={isInView ? 'show' : 'exit'}>
          <Text
            textAlign={'center'}
            fontWeight={800}
            color={'#010155'}
            sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
            fontSize={40}
            fontFamily={'abhaya-libre-extrabold'}
          >
            <Text>Choose Perfect </Text>
            <Text>Career Path with us!</Text>
          </Text>
          </MotionBox>
          <MotionBox 
          ref={ref}
          variants={fadeIn('left', 0.7)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3}}
          animate={isInView ? 'show' : 'exit'}>
          <Image
            height={{ xl: 560, base: 300 }}
            width={{ xl: 550, base: 300 }}
            src={require('../images/careerImg(1).png')}
          />
          </MotionBox>
        </Flex>
        <Text
          color={'rgba(1, 1, 85, 1)'}
          textAlign={'center'}
          fontSize={30}
          m={3}
          fontFamily={'abhaya-libre-extrabold'}
          fontWeight={800}
        >
          See your Future with us
        </Text>
      </Box>
      <Image
        marginTop={{ xl: -24 }}
        marginLeft={10}
        height={70}
        src={require('../images/starImg.png')}
      />
      <MotionBox
      
      viewport={{ once: false, amount: 0.3 }}
        background={
          'conic-gradient(from 35.64deg at -31.94% 88.73%, #F8F8F8 0deg, #F9F9F9 0.04deg, #786779 45deg, #EDCFED 66.73deg, #CBA9A9 75.6deg, #FFD1D1 75.64deg, #EFFFFF 140.4deg, #F9F9F9 310.43deg, #F8F8F8 360deg)'
        }
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={{ xl: 2, base: -20 }}
      >
        {clicked === false && (
          <FormControl
            marginLeft={{ xl: '18%' }}
            width={'100%'}
            alignContent={'center'}
            padding={{ xl: 20 }}
            paddingX={{ base: 10 }}
            fontFamily={'arvo-regular'}
            paddingY={{ base: 14 }}
          >
            <Box variants={fadeIn('up', 0.7)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.3 }}>
            <FormLabel margin={5} fontSize={18} fontFamily={'arvo-regular'}>
              Enter your name
            </FormLabel>
            <Input
              required
              outline={'none'}
              border={'none'}
              type="text"
              padding={7}
              borderRadius={50}
              width={{ xl: '70%', base: '100%' }}
              background="#D8C9D8"
              _focus={{
                outline: 'none',
                border: 'none',
                boxShadow: 'none',
              }}
              boxShadow={'2px 3px 4px 0px #00000040'}
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Box>
          <Box variants={fadeIn('up', 0.7)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.3 }}>
            <FormLabel margin={5} fontSize={18} fontFamily={'arvo-regular'}>
              Enter your email
            </FormLabel>
            <Input
              required
              type="email"
              padding={7}
              borderRadius={50}
              border={'none'}
              width={{ xl: '70%', base: '100%' }}
              background="#D8C9D8"
              _focus={{
                outline: 'none',
                border: 'none',
                boxShadow: 'none',
              }}
              boxShadow={'2px 3px 4px 0px #00000040'}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            </Box>
            <Box variants={fadeIn('up', 0.7)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.3 }}>
            <FormLabel margin={5} fontSize={18} fontFamily={'arvo-regular'}>
              Enter your query
            </FormLabel>
            <Textarea
              type=""
              required
              border={'none'}
              padding={6}
              multiple={true}
              noOfLines={5}
              borderRadius={50}
              width={{ xl: '70%', base: '100%' }}
              background="#D8C9D8"
              _focus={{
                outline: 'none',
                border: 'none',
                boxShadow: 'none',
              }}
              height={251}
              boxShadow={'2px 3px 4px 0px #00000040'}
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
            </Box>
            <Box variants={fadeIn('up', 0.7)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.3 }}>
            <Flex
              width={{ xl: '70%', lg: '100%', md: '100%', base: '100%' }}
              justifyContent={'center'}
            >
              <Box
                boxShadow={'2px 3px 4px 0px #00000040'}
                width={{ xl: '40%', lg: '30%', md: '70%', base: '90%' }}
                fontWeight={500}
                my={5}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                fontFamily={'arvo-regular '}
                borderRadius={20}
                bg={'#D8C9D8'}
                py={3}
                px={2}
              >
                Upload your resume
                <Input
                  required
                  type="file"
                  display={'none'}
                  onChange={handleChange}
                  ref={inputFile}
                />
                <Button
                  onClick={() => inputFile.current.click()}
                  background="transparent"
                  _hover={{ background: 'transparent' }}
                  _focus={{ boxShadow: 'none' }}
                >
                  ➕
                </Button>
              </Box>
            </Flex>
            <Flex
              width={{ xl: '70%', lg: '100%', md: '100%', base: '100%' }}
              justifyContent={'center'}
            >
              <Button
                width={{
                  xl: '38%',
                  lg: '30%',
                  md: '70%',
                  sm: '90%',
                  base: '90%',
                }}
                paddingX={10}
                paddingY={6}
                borderRadiusF={45}
                background="#D8C9D8"
                _hover={{ background: '#CBB8C9' }}
                _focus={{ boxShadow: 'none' }}
                boxShadow={'2px 3px 4px 0px #00000040'}
                fontSize={18}
                fontFamily={'arvo-regular'}
                // onClick={handleSubmit}
                isDisabled={!isValid}
              >
                Submit
              </Button>
            </Flex>
            </Box>
          </FormControl>
        )}
        {clicked === true && (
          <Box
            backgroundImage={require('../images/careerBG.png')}
            backgroundSize={'cover'}
            width={'100%'}
            height={'500px'}
            backgroundPosition="center"
          >
            <Image
              paddingTop={{
                xl: '40px',
                lg: '80px',
                md: '80px',
                sm: '100px',
                base: '100px',
              }}
              width={{
                xl: '20%',
                lg: '20%',
                md: '30%',
                sm: '35%',
                base: '40%',
              }}
              margin={'auto'}
              src={require('../images/checkIcon.png')}
            />
            <Text
              fontFamily={'arvo-regular'}
              color={'rgba(102, 125, 229, 1)'}
              fontSize={40}
              textAlign={'center'}
            >
              SUBMITTED
            </Text>
          </Box>
        )}
      </MotionBox>
      <Footer />
    </Box>
  );
};

export default Career;
