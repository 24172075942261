export const fadeIn = (direction, delay) => {
    return {
      hidden: {
        y: direction === 'up' ? 40 : direction === 'down' ? -40 : 0,
        x: direction === 'left' ? 80 : direction === 'right' ? -120 : 0,
        opacity: 1,
        transition: {
          type: 'tween',
          duration: 0.6,
          delay: delay,
          ease: [.25, .25, .25, .75],
        }
      },
      show: {
        y: 0,
        x: 0,
        opacity: 1,
        transition: {
          type: 'tween',
          duration: 0.6,
          delay: delay,
          ease: [.25, .25, .25, .75],
        }
      },
      exit: {
        y: direction === 'up' ? 40 : direction === 'down' ? -40 : 0,
        x: direction === 'left' ? 80 : direction === 'right' ? -120 : 0,
        opacity: 1, 
        transition: {
          type: 'tween',
          duration: 0.6,
          delay:delay,
          ease: [.25, .25, .25, .75],
        }
      }
    };
  };